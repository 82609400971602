<template>
  <div>
    <v-container>
      <v-textarea
        v-model="input_text"
        auto-grow
      />
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="spamReports({searchTerm: input_text})"
        >
          SPAM REPORTS
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="bounces({searchTerm: input_text})"
        >
          BOUNCES
        </v-btn>
      </v-card-actions>
    </v-container>
    <div v-if="leads">
      <v-card>
        <v-card-text>
          <LeadCard v-for="lead in leads" :lead="lead"
                    :key="lead.id"
          >  </LeadCard>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="closeSearchDlg"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import LeadCard from "@/components/leads/LeadCard.vue";

export default {
  components: {
    LeadCard
  },
  data() {
    return {
      input_text: "",
      searchResultDialog: false,

    };
  },
  methods: {
    ...mapActions("leads", [
      "search", "spamReports", "bounces"
    ]),
    async closeSearchDlg() {
      this.searchResultDialog = false;
    },
  },
  computed: {
    ...mapState("leads", ["leads"]),
  },
};
</script>

<style></style>
